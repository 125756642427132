import { Footer } from "../../Components/footer/footer";
import Menu from "../../Components/menu/Menu";
import HeroNeon from "../../Components/hero/HeroNeon";

export function Page404() {


    return (

        <>
            <div id="main">
            <h1>Mehdi Habibi - 404 Not Found</h1>
        <HeroNeon
            title="404"
        ></HeroNeon>
        
      </div>
        </>
    )
}